import React from 'react';
import { Link } from 'react-router-dom';
import './Beneficios.css';
import EnConstruccion from '../Encontruccion/EnContruccion';

function Beneficios({ isAuthenticated }) {
    if (!isAuthenticated) {
        return (
            <div className="beneficios-container">
                <div className="auth-card">
                    <p>Por favor, inicia sesión para ver los beneficios exclusivos de DeterMiau.</p>
                    <Link to="/auth" className="auth-button">Iniciar Sesión</Link>
                </div>
            </div>
        );
    }

    return (
        <div>
        <EnConstruccion/>
    </div>
    );
}

export default Beneficios;
