import React from 'react';
import EnConstruccion from '../Encontruccion/EnContruccion';

function GuiaLavado() {
    return (
        <div>
        <EnConstruccion/>
    </div>
    );
}

export default GuiaLavado;
