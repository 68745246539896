import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../../img/LogoMiau.png";
import { FaBars, FaCoins } from 'react-icons/fa';
import './Cabecera.css';

function Cabecera({ isAuthenticated, onLogout, userCredit }) {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // Función para cerrar el menú al hacer clic en una opción
    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <header className="cabecera">
            <div className="cabecera-logo">
                <img src={logo} alt="Logo" className="logo" />
                <h1 className="titulo">DeterMiau</h1>
            </div>
            <button className="menu-button" onClick={toggleMenu}>
                <FaBars />
            </button>
            <nav className={`navegacion ${menuOpen ? 'open' : ''}`}>
                <Link to="/" className="enlace" onClick={closeMenu}>Inicio</Link>
                <Link to="/productos" className="enlace" onClick={closeMenu}>Productos</Link>
                <Link to="/guia-de-lavado" className="enlace" onClick={closeMenu}>Guía de lavado</Link>
                <Link to="/beneficios" className="enlace" onClick={closeMenu}>Beneficios</Link>
                <Link to="/contacto" className="enlace" onClick={closeMenu}>Contacto</Link>
                
                {isAuthenticated ? (
                    <>
                        <div className="credito-usuario">
                            <FaCoins className="icono-credito" /> {userCredit} puntos
                        </div>
                        <button onClick={() => { onLogout(); closeMenu(); }} className="enlace boton-salir">Salir</button>
                    </>
                ) : (
                    <Link to="/auth" className="enlace" onClick={closeMenu}>Login / Registrarse</Link>
                )}
            </nav>
        </header>
    );
}

export default Cabecera;
