import React from 'react';
import EnConstruccion from '../Encontruccion/EnContruccion';

function Contacto() {
    return (
        <div>
        <EnConstruccion/>
    </div>
    );
}

export default Contacto;
