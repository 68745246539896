import React, { useState } from 'react';
import { auth, db } from './firebaseConfig';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, set } from "firebase/database";
import Modal from './Modal';
import './Register.css';

function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleRegister = async (event) => {
        event.preventDefault();

        try {
            // Crear usuario en Firebase Authentication
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Guardar datos adicionales en Realtime Database
            await set(ref(db, 'users/' + user.uid), {
                username: username,
                email: email,
                credit: 0 // Crédito inicial
            });

            setIsModalOpen(true); // Abre el modal cuando la cuenta se crea exitosamente
        } catch (error) {
            console.error("Error al crear la cuenta:", error);
            alert("Error al crear la cuenta: " + error.message);
        }
    };

    return (
        <div className="form-container">
            <h2 className="form-title">Crear Cuenta</h2>
            <form className="form" onSubmit={handleRegister}>
                <label className="form-label">Nombre de Usuario</label>
                <input 
                    type="text" 
                    className="form-input" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                    required 
                />
                
                <label className="form-label">Correo Electrónico</label>
                <input 
                    type="email" 
                    className="form-input" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    required 
                />
                
                <label className="form-label">Contraseña</label>
                <input 
                    type="password" 
                    className="form-input" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    required 
                />
                
                <button type="submit" className="form-button">Registrarse</button>
            </form>

            {/* Modal de confirmación de cuenta creada */}
            <Modal 
                isOpen={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                title="¡Cuenta Creada!"
            >
                <p>Tu cuenta ha sido creada exitosamente. Ahora puedes iniciar sesión.</p>
            </Modal>
        </div>
    );
}

export default Register;
