import React from 'react';
import EnConstruccion from '../Encontruccion/EnContruccion';

function Productos() {
    return (
        <div>
            <EnConstruccion/>
        </div>
    );
}

export default Productos;
