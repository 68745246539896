import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Fondo from '../../img/Fondo.png';
import FondoMovil from '../../img/FondoMovil.png';
import Imagen1 from '../../img/LogoMiauSinLetras.png'; // Ejemplo de imagen
import Imagen2 from '../../img/RopaColor.png';
import Imagen3 from '../../img/Suavizante.png';
import Imagen4 from '../../img/Multiusos.png';
import Imagen5 from '../../img/Recicla.png';
import PinoImg from '../../img/Pino.png';
import CuidadoPrendas from '../../img/cuidadoDePrendas.png';
import RRR from '../../img/RRR.png';
import JabonDeterMiauPlus from '../../img/DeterMiauPlus.png';
import SuaveMiau from '../../img/SuaveMiau.png';
import Aquamiau from '../../img/AquaMiau.png';
import './Inicio.css';

function Inicio() {
    const backgroundImage = window.innerWidth <= 768 ? FondoMovil : Fondo;

    const carouselContent = [
        {
            title: "Bienvenido a DeterMiau",
            text: "Descubre la frescura y el cuidado que tu ropa merece.",
            image: Imagen1,
        },
        {
            title: "Cuida y Realza tu Ropa con DeterMiau",
            text: "Mantén tus prendas como nuevas: cuidamos los colores, protegemos las fibras y ofrecemos una frescura duradera.",
            image: Imagen2,
        },
        {
            title: "Reduce y Rellena tus Envases",
            text: "Puedes rellenar tus envases de productos de limpieza en nuestros despachadores automáticos, evitando el uso de plásticos innecesarios y cuidando el planeta con cada uso.",
            image: Imagen5,
        },
        {
            title: "Calidad y Cuidado para tu Hogar",
            text: "Nuestros productos de limpieza están diseñados para ofrecer una limpieza profunda y segura, cuidando tus espacios y manteniéndolos frescos cada día.",
            image: Imagen4,
        },
        {
            title: "Aroma Duradero con Microcápsulas",
            text: "Nuestros suavizantes con microcápsulas liberan fragancia gradualmente, manteniendo tus prendas frescas y suaves durante todo el día.",
            image: Imagen3,
        },
    ];

    const productCards = [
        {
            image: JabonDeterMiauPlus, // Reemplaza con la ruta real de la imagen
            title: "DeterMiau+",
            description: "🌈 Revive los colores y cuida tu ropa con cada lavada. ¡El aliado perfecto para tus prendas favoritas!",
        },
        {
            image: SuaveMiau, // Reemplaza con la ruta real de la imagen
            title: "SuaveMiau",
            description: "✨ Deja tu ropa suave y perfumada todo el día con nuestras microcápsulas de frescura. ¡Un toque de suavidad en cada uso!",
        },
        {
            image: Aquamiau, // Reemplaza con la ruta real de la imagen
            title: "AquaMiau",
            description: "💧 Limpieza profunda y frescura para tu hogar. Multiusos con aroma revitalizante, ideal para cada rincón.",
        },
    ];
    

    const tipsContent = [
        {
            title: "Cómo cuidar tus prendas",
            text: "Lava tus prendas del revés para proteger los colores y tejidos.",
            image: CuidadoPrendas,
            link: "/guia-de-lavado", // Enlace al componente Guía de Lavado
        },
        {
            title: "Limpieza ecoamigable",
            text: "Reutiliza tus envases con nuestros despachadores automáticos.",
            image: RRR,
            link: "/guia-de-lavado",
        },
        {
            title: "El poder del Aceite de Pino 🌲",
            text: "Descubre los beneficios de limpiar con pino DeterMiau",
            image: PinoImg,
            link: "/guia-de-lavado",
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false
    };

    return (
        <div
            className="inicio-background"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="inicio-overlay"></div>
            <div className="inicio-content">
            <div className="floating-carousel">
                    <Slider {...settings}>
                        {carouselContent.map((slide, index) => (
                            <div key={index} className="carousel-card">
                                <img src={slide.image} alt={slide.title} className="carousel-image" />
                                <h2>{slide.title}</h2>
                                {slide.text.split('\n').map((line, i) => (
                                    <p key={i}>{line}</p>
                                ))}
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>

             {/* Sección de productos */}
            <div className="products-section">
                <h2>¡Descubre la diferencia! 🌟 Prueba DeterMiau.🧽✨</h2>
                <div className="products-list">
                    {productCards.map((product, index) => (
                        <div key={index} className="product-card">
                            <img src={product.image} alt={product.title} className="product-image" />
                            <h3>{product.title}</h3>
                            <p>{product.description}</p>
                            <Link to="/productos">
                                <button className="view-more-button">Ver Productos</button>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>


            {/* Nueva sección: Consejos, Tips y Más */}
            <div className="tips-section">
                <h2>Tips para una Limpieza Inteligente</h2>
                <div className="floating-carousel">
                    <Slider {...settings}>
                        {tipsContent.map((tip, index) => (
                            <div key={index} className="carousel-card">
                                <img src={tip.image} alt={tip.title} className="carousel-image" />
                                <h2>{tip.title}</h2>
                                <p>{tip.text}</p>
                                <Link to={tip.link}>
                                    <button className="view-more-button">Ver Guia</button>
                                </Link>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>

    


        </div>
    );
}

export default Inicio;
